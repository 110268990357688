import React, { useMemo } from "react";
import _ from "lodash";
import RecommendationItem from "./RecommendationItem";
import { TRecommendationListItem } from "../../lib/types";

interface IRecommendationsList {
  recommendations: TRecommendationListItem[];
  displaySource?: boolean;
}

const RECOMMENDATIONS_ORDER = [
  "2908b8f8-6d05-44f9-ac87-4362ace455cb",
  "1892e9ae-e858-4214-b711-a5d830b4dd59",
  "3c665ab5-96a2-4519-8617-3b047e30efeb",
  "7a35f9d5-c9cb-4848-8cd9-e665efe57987",
  "00f30e91-1387-4c33-a5a1-ac539d5a4b36",
  "99cbc3b3-afb7-4ba2-be64-8e3e22a157f6",
  "0b856b0f-003e-46dd-a778-dbbf78764aaf",
  "a78c9a38-9ec3-43eb-acd0-23bf87ce578c",
  "d8605338-9ecf-43f2-95bf-215bf44dde08",
  "f71f14aa-a3d1-4185-b398-d70af9ee5b35",
  "ff44277d-1d05-43e0-9c94-e5263d0808f2",
  "6f555913-eb6b-4844-b127-e5f72b697ca7",
  "fd22281b-f052-466e-b7f0-d52452e6832b",
  "5adae459-58c9-48f4-8658-f420b3e6ca31",
  "ccf3da2d-aab3-4a31-b50b-34af658dc5c1",
  "8f9fe2e1-af01-4503-8c34-5f8fe4d3425a",
  "2b83a3c2-2430-45a1-b5f2-17339c75bfd3",
  "a1c1a542-5341-4d49-8f92-3e38b132506c",
  "2fae7f9e-a251-451b-9234-f86f24c4eada",
  "396eb58f-63b2-47ba-b6e7-dc9cc23ef3e0",
  "6b79f8de-b902-44b0-b55d-4598bf926b81",
  "2724a16b-1ec6-449a-9f01-2abb8176d2d5",
  "c65bd000-23d4-4fbd-8a77-27a79f45c3b6",
  "92a2c0b5-5e5c-431a-9fa7-7c5f64dcf9a4",
  "42821cad-435a-47d6-9bcf-32bc9d53b4aa",
  "2d9351bd-962f-4579-8882-af45b2b0bf3a",
  "6b5bce7b-6081-47b3-9513-1547c3379921",
  "085cb5e4-da87-4017-b3a7-3f1c0254f2ef",
  "764ff2c8-b6f0-44f7-ac42-26c517985179",
  "6904ba3b-7b35-4cc5-9f8e-1f5dfb0a4d44",
  "2e9f50ae-2494-4901-8dcc-57e1dc0ede4e",
  "ede6b909-e6a9-444c-8847-452076e05c71",
  "03d509e1-0cc7-46ee-a09a-e1cbbac303b8",
  "5d54392c-f542-40ee-a7cb-181055097352",
  "d898a098-8726-4be6-b477-a5951adb62ce",
  "24c96691-691b-4dca-aeef-49a45791d319",
  "a5a56fe7-3266-4854-8c91-036e572cf71c",
  "42783958-5d33-4366-9a5e-25a48571c409",
  "714914f3-a201-4e35-81b1-19320e5c8310",
  "86804ffd-33fa-474e-bd81-f217f6998f8d",
  "1b8e1d22-7cc5-4bdd-87d3-c6b3dd9ede10",
  "c4309004-1088-4af4-9877-49498df61f20",
  "3e4c25d1-75af-4c9e-b904-eaf00f0f8477",
  "004e3100-175a-4881-bfd7-a655a6fb619e",
  "5548b898-28c1-4b9f-a97b-a05dce825ffd",
  "44062329-4ea8-452e-b456-8d39c607f903",
  "3e78a776-1985-438c-b585-7d846ec610a8",
  "5724a137-6f8c-4950-9d7f-29ca8863ae10",
  "c35f62b7-6506-4e21-80f1-8db795eb294c",
  "9164e2e6-9cc6-4820-90ff-f4d9c5a472c7",
  "04775290-87ba-4341-9919-410ee90bf2b8",
  "0f7f3d9f-4e74-4e96-9ec6-03c069e069f1",
  "38d8a0fa-e5b6-4714-acdc-117f14c9b708",
  "8d2e1653-e860-4c96-9162-66837f6f5fdb",
  "67045d71-e200-46e1-a29b-5338fc13bce4",
  "196d408c-3578-404a-82c9-bf2b74ddbb7c",
  "62b439c2-84b5-4d73-abfc-3f940920693a",
  "a66eb3ca-cf91-4bb6-9095-4371e6a96e63",
  "07e78ee4-4674-4a24-b2d9-2d4a9691df02",
  "f6d21fc4-d045-4003-b1b6-b0a91dd47ab0",
  "f048d317-b61f-4177-9dcb-a94a6f8f3241",
  "2a92ea34-dc3a-4064-af5a-6e9834df99d0",
  "31e654b9-268f-429a-8fb6-73319306a8ac",
  "c27feec7-5885-457e-8d50-f4a8b7e2d7e2",
  "6df4ef25-c88f-48bf-8462-f5d21c5f9d50",
  "7eb576ce-218b-499e-b076-1e3a43acf27e",
  "51e38b8a-44e4-4e08-a08c-b6eb3b2334a4",
  "071d5c49-7644-44c4-9d6c-28211a39bf68",
  "ef71f309-d354-4b67-8e7e-b304c1dac16d",
  "a4605a62-cb5c-4e07-8209-2b1aa6fee4f9",
  "d8e57119-fe30-4d31-bb9f-063af419907b",
  "bf7e880f-b74e-4d82-a087-836b507e9266",
  "d127897c-1105-4aff-a729-517bd6d6d74f",
  "f825332d-1c75-49ef-974d-a92799258d3a",
  "c432eea3-6d51-4999-ab73-d512d70f4965",
  "266ffa8b-8941-4c09-b358-9a5c8bcaede3",
  "8575dde6-8502-403e-8fff-d0a49e23c298",
  "c958ce3d-a6f1-42ca-9dba-dbcadd37079f",
  "3fa55c05-5cd8-4b71-948a-d8369d2308b9",
  "fba9e49e-c1fa-47c7-9d50-049721d0cf16",
  "f78e4b41-6bf6-4fde-bee2-a2945fa08194",
  "2ab3bf3a-8db6-40fc-9017-68ea9f77f981",
  "d6851924-cc4e-43ea-9c9b-7469db661e04",
  "e7f02a1b-c7e9-495a-848c-c8f891f38757",
  "a9ec9c0e-319a-40a9-b34f-0a7ffbea6e0a",
  "a064491e-6b68-4e6d-b666-139c6a6bb50d",
  "0ae7603e-610b-44b1-b7b4-fd64585438dd",
  "cf2e5e87-c465-4154-9944-94cabbb1ded1",
  "cdde544f-e773-4fd9-bab5-16e8ee4f8cb9",
  "65bf69e8-e52f-4f37-9e0f-2aa61d1462d0",
  "8d2649a0-c024-47d5-b79e-48377571dac0",
  "3e20c674-0162-42d5-9cc9-31fdc3b57247",
  "f9246f25-3f0c-4533-b266-1653a8f737f6",
  "f0911af2-6a46-4e47-9153-e5f97e9f1a0a",
  "f40c1639-ddc0-4cc9-a951-267c1dae348a",
  "e99d30de-0cff-43c5-bc23-899abc4460cb",
  "9c2c4e50-c6ed-4cd9-8101-1a56f3ccbdf6",
  "9424a97d-079f-4b14-873b-3de765823527",
  "22026b93-2467-4c37-8423-ffd3635bd97f",
  "157c7a7f-846d-4681-8d57-236abd6056e6",
  "9065083f-bf8c-4660-8281-2ee18c2877b4",
  "75aba5cb-c449-4a31-9b12-3d3b169384fe",
  "52ab6f35-fe0f-481f-8efd-e96140b990b1",
];

const RecommendationsList = ({ recommendations, displaySource = true }: IRecommendationsList) => {
  const recommendationsKeyed = useMemo(() => _.keyBy(recommendations, "@id"), [recommendations]);

  return (
    <ul className="recommendation-list ml-1 md:ml-1 mt-6 mr-1 md:mr-2">
      <li>
        <div className="bg-transparent pb-1">
          <div className="mb-1">
            {_.map(RECOMMENDATIONS_ORDER, (recommendationId) => {
              return recommendationsKeyed[recommendationId] ? (
                <RecommendationItem
                  key={recommendationId}
                  recommendation={recommendationsKeyed[recommendationId]}
                  displaySource={displaySource}
                />
              ) : null;
            })}
          </div>
        </div>
      </li>
    </ul>
  );
};

export default RecommendationsList;
